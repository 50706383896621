export default {
  shop_center_txt: "Trung tâm mua sắm",
  score_txt: "Điểm",
  minute_txt: "phút",
  wallet_txt: "ví",
  today_txt: "Hôm nay",
  week_txt: "Tuần này",
  month_txt: "Tháng này",
  year_txt: "Cả năm",
  main_indicators_txt: "Các chỉ báo chính",
  sale_price: "Gía bán",
  expected_profit: "Lợi Nhuận Dự Kiến",
  order_count: "Số lượng đặt hàng",
  goods_click_count: "Số lần nhấp chuột vào sản phẩm",
  supplier_txt: "Nhà cung cấp",
  friends_help_txt: "Bạn bè giúp đỡ",
  feedback_txt: "Phản hồi",
  feedback: "Nhận xét",
  online_service_txt_1: "Dịch vụ khách hàng gửi tiền",
  online_service_txt_2: "Tư vấn kinh doanh",
  q_and_a_txt: "Hỏi đáp",
  open_now_txt: "Mở",
  certification_activation_code: "Mã kích hoạt chứng chỉ",
  plz_write_invite_code: "Vui lòng điền mã mời",
  time_txt: "thời gian",
  times_txt: "thời gian",

  wallet_total_assets: "Tổng tài sản",
  balance_txt: "Số dư",
  pending_settlement_txt: "đang chờ giải quyết",
  recharge_txt: "Nạp tiền",
  withdraw_txt: "Rút tiền",
  fund_details_txt: "Chi tiết quỹ",
  no_more_txt: "Không còn nữa",
  no_data_txt: "Chưa có dữ liệu",
  detail_txt: "Chi tiết",
  num_txt: "Số",
  money_txt: "số tiền",
  remark_txt: "Nhận xét",
  no_remark_txt: "Không có bình luận",
  tip_txt: "mẹo",
  tip_content_txt:
    "Hiện tại do vấn đề tiền tệ được bảo lãnh bởi người bán ở nhiều quốc gia khác nhau",
  need_contact_service: "Cần liên hệ với bộ phận dịch vụ khách hàng",
  contact_service: "Liên hệ bộ phận dịch vụ khách hàng",

  feedback_title: "Phản hồi ý kiến ​​của bạn",
  feedback_placeholder:
    "Vì lý do bảo mật, vui lòng không đưa thông tin cá nhân",
  submit_txt: "Gửi",
  sure_txt: "Được",
  submit_suc_txt: "Gửi thành công",

  loading: "Đang tải...",

  new_action: "Hoạt động của người mới",
  newer_traffic_package: "Gói lưu lượng hỗ trợ người mới",
  newer_get_millions_of_products: "Nhận hàng triệu sản phẩm",
  newer_get: "đã nhận",
  newer_receive: "nhận",
  newer_action_ruler: "Quy tắc hoạt động của người mới",
  newer_receive_suc: "Đã nhận thành công",

  help_friends_help: "Bạn bè giúp đỡ",
  help_your_friends_stores: "Giúp đỡ bạn bè của bạn",
  get_store_product_heating: "Nhận sản phẩm sưởi ấm tại cửa hàng",
  help_friends_txt: "Giúp đỡ bạn bè",
  help_friends_activity_rules: "Trợ giúp các quy tắc hoạt động của bạn bè",
  assistance_details: "Chi tiết hỗ trợ",
  assistance_details_other: "Chi tiết được hỗ trợ",
  team_list: "Danh sách đội",
  help_friend_name: "Giúp đỡ tên bạn bè",
  plz_input: "Xin vui lòng nhập",
  plz_input_friend_name:
    "Xin vui lòng nhập tên bạn bè của bạn để được trợ giúp",
  help_suc: "Giúp thành công",

  online_service: "Dịch vụ khách hàng trực tuyến",
  online_24_hour_service: "Hỗ trợ trực tuyến 24 giờ",
  customer_service: "dịch vụ khách hàng",
  copy_and_add_id: "Sao chép và thêm ID LINE",
  plz_input_question_detail: "Vui lòng nhập chi tiết câu hỏi của bạn",
  harry_reply_your: "Chúng tôi sẽ trả lời bạn sớm nhất có thể",
  click_copy: "Click để sao chép",
  copy_suc: "Sao chép thành công",
  copy_fail: "Sao chép thất bại",

  overall: "tổng thể",
  cooperate: "hợp tác",
  cancel: "hủy",
  supplier_num: "Mã số nhà cung cấp",
  no_use_supplier: "Không có nhà cung cấp nào",
  here_add_supplier: "Thêm nhà cung cấp mới tại đây",
  add_supplier: "Thêm nhà cung cấp",
  plz_input_supplier_num: "Xin vui lòng nhập số nhà cung cấp",
  add_suc: "Thêm thành công",
  remove_suc: "Đã xóa thành công",

  new_products_launched: "Ra mắt sản phẩm mới",
  profit_txt: "Lợi nhuận",
  has_been_added: "Đã có trên kệ",
  on_display: "trên màn hình",
  coming_soon_txt: "Có sẵn ngay bây giờ",
  sold_txt: "Đã bán",
  removed_txt: "Đã xóa",

  order_detail_txt: "Chi tiết đơn hàng",
  waiting_express_delivery: "Đang chờ chuyển phát nhanh",
  customer_info: "Thông tin khách hàng",
  order_good: "Đặt hàng",
  order_num: "Mã đơn hàng",
  order_date: "ngày đặt hàng",
  order_content: "Tóm tắt đơn hàng",
  purchase_price_txt: "Giá mua",
  income_txt: "Thu nhập",

  order_txt: "Đặt hàng",
  pending_payment: "Thanh toán đang chờ xử lý",
  ready_for_shipment: "Sẵn sàng giao hàng",
  shipped_txt: "Đã vận chuyển",
  paid_txt: "Đã giao",
  good_num: "Số mục",
  pay_and_ship: "Thanh toán và Giao hàng",
  pay_and_ship_all: "Thanh toán tất cả",
  pay_suc: "Thanh toán thành công",
  no_order: "Chưa có đơn hàng nào",
  no_order_tips: "Bạn có thể theo dõi đơn hàng và trả lại tại đây",
  suer_order: "Xác nhận thanh toán",
  pay_way: "Phương thức thanh toán",
  wallet_pay: "Thanh toán bằng ví",
  sure_pay: "Xác nhận thanh toán",
  paying_txt: "Đang thanh toán...",

  manage_showcase: "Quản lý trưng bày",
  add_new_good: "Thêm sản phẩm mới",
  heating_txt: "Sưởi ấm",
  promotion: "khuyến mãi",
  promotion_tip: "Mẹo khuyến mãi",
  promotion_tip_txt:
    "Điểm hiện tại của bạn dưới 200 và bạn không thể thanh toán cho sản phẩm của mình",
  copy_link: "Sao chép liên kết",
  scan_qr_code: "Quét mã QR",

  about_good: "Giới thiệu về sản phẩm",
  specification: "đặc điểm kỹ thuật",
  product_overview: "Tổng quan về sản phẩm",

  top_tips_1: "Chuyển đổi cửa hàng Douyin trực tuyến",
  top_tips_2: "Hiện tại chỉ hỗ trợ lời mời nội bộ",
  active_place_holeder: "Xin vui lòng nhập",

  promotion_01: "khuyến mãi",
  promotion_02: "Số tiền khuyến mãi",
  promotion_03: "Vui lòng nhập số tiền khuyến mãi",
  promotion_04: "Xác nhận khuyến mãi",
  promotion_05: "Khuyến mãi thành công",

  bao_txt: "tỷ lệ tiếp xúc",
  bal_no: "Không đủ số dư",

  wallet_type_01: "Ứng dụng nạp tiền",
  wallet_type_02: "Ứng dụng rút tiền",
  wallet_type_03: "Thanh toán",
  wallet_type_04: "Hệ thống khấu trừ",
  wallet_type_05: "Thu nhập lợi nhuận",
  wallet_type_06: "Khấu trừ thanh toán",
  wallet_type_07: "Tiêu thụ phân phối",
  wallet_type_08: "Doanh thu khuyến mãi",
  wallet_type_09: "Tăng phần thưởng",
  wallet_type_10: "Đăng ký miễn phí",
  wallet_type_11: "Phần thưởng doanh thu",
  wallet_type_12: "Việc rút tiền bị từ chối",

  all_orders: "Tất cả đơn hàng",
  mine_orders: "Đơn hàng của tôi",
  mine_services: "Dịch vụ của tôi",
  _tzz: "Nhảy...",

  info_01: "TÊN NGƯỜI NHẬN",
  info_02: "Email",
  info_03: "Thông tin liên hệ",
  info_04: "Giới tính",
  info_05: "Bí mật",
  info_06: "Nam",
  info_07: "Nữ",
  info_08: "Ngày sinh(yyyy-MM-dd)",
  info_09: "Quốc gia",
  info_10: "Thành phố",
  info_11: "SỐ TÀI KHOẢN",
  info_12: "NGÂN HÀNG",
  info_13: "Nhà phát hành thẻ",
  info_14: "Ngày hết hạn(yyyy-MM-dd)",
  info_15: "Mã CVV",
  info_16: "Mật khẩu bảo mật",
  info_17: "Lặp lại mật khẩu bảo mật",
  info_18: "Xin vui lòng nhập lại mật khẩu bảo mật",
  info_19: "Hai mật khẩu bảo mật không nhất quán",
  info_20: "CMND/hộ chiếu",
  info_21: "Chọn loại hình ảnh để tải lên",
  info_22: "CMND (mặt trước và mặt sau)",
  info_23: "Hộ chiếu",
  info_24: "Giấy phép lái xe",
  info_25: "Giấy phép cư trú",
  info_26: "Giấy chứng nhận sức khỏe",
  info_27: "Vui lòng tải ảnh CMND lên",
  info_28: "Đã gửi, vui lòng kiên nhẫn chờ đợi",
  info_29: "Số tài khoản",
  info_30: "Tên ngân hàng",

  wl_withdraw_money: "Số tiền rút",
  wl_withdraw_code_201:
    "Hồ sơ rút tiền đang được xem xét, vui lòng không gửi lại",
  wl_withdraw_code_202: "Thông tin thẻ ngân hàng không chính xác",
  wl_withdraw_code_400: "Ngoại lệ hồ sơ rút tiền",
  wl_withdraw_code_204: "Mật khẩu bảo mật không chính xác",
  wl_withdraw_code_205: "Không thể rút số dư ký quỹ",
  wl_withdraw_code_207:
    "Không thể rút xếp hạng sao của người dùng hiện tại, vui lòng tăng xếp hạng sao",
  wl_withdraw_code_208:
    "Xếp hạng sao của người dùng hiện tại đã đạt số lần rút tiền tối đa ngày hôm nay",

  wl_withdraw_order_01: "Tất cả",
  wl_withdraw_order_02: "Chưa được xem xét",
  wl_withdraw_order_03: "Đã kiểm toán",
  wl_withdraw_order_04: "Rút tiền không thành công",
  wl_withdraw_order_05: "Số",
  wl_withdraw_order_06: "Số tiền rút",
  wl_withdraw_order_07: "Phương thức rút tiền",
  wl_withdraw_order_08: "Thông tin rút tiền",
  wl_withdraw_order_09: "Sẽ được xem xét",
  wl_withdraw_order_10: "Đã phê duyệt",
  wl_withdraw_order_11: "Hoàn thành",
  wl_withdraw_order_12: "Đã hủy",
  wl_withdraw_order_13: "đóng băng",
  wl_withdraw_order_14: "Lý do rút tiền không thành công",

  hl_mine_invite_code: "Mã mời của tôi",
  hl_help_friend_invite_code: "Trợ giúp mã mời kết bạn",
  hl_certification_activation_code: "mã thư mời",
  hl_plz_write_invite_code: "Vui lòng nhập mã mời",
  hl_help_code_202: "Mã mời trợ giúp sai",
  hl_help_code_203: "Mã lời mời trợ giúp không thể bị ràng buộc với chính nó",
  hl_help_code_205:
    "Đã đạt đến số lượng hỗ trợ tối đa và không được phép hỗ trợ thêm nữa",
  hl_help_code_206:
    "Người bạn hiện tại đã giúp đỡ rồi và không thể giúp đỡ nữa",
  gl_visits: "Chuyến thăm",

  _in_sp: "Xếp hạng sản phẩm",
  _r_k01: "Xếp hạng",
  _r_k02: "Hàng hóa",
  _r_k03: "nhiệt",

  _zw_ph: "Chưa có thông tin xếp hạng sản phẩm! ",
  _zf_201: "Trạng thái đơn hàng bất thường",
  _zf_202: "Số dư tài sản không đủ",
  _zf_203: "Thanh toán không thành công",
  _zf_204:
    "Giao dịch của người bán đã bị đóng băng, vui lòng liên hệ với bộ phận chăm sóc khách hàng",

  _withdraw_order_txt: "Lệnh rút tiền",

  shop_num: "số cửa hàng",
  plz_input_shop_num: "Vui lòng nhập số cửa hàng",
  shop_num_error: "Số cửa hàng không đúng",
  supplier_num_error: "Mã nhà cung cấp không chính xác",
  supplier_num_has_bound: "Số nhà cung cấp đã bị ràng buộc",

  sc_day_sale: "Doanh số hàng ngày",
  sc_week_sale: "bán hàng hàng tuần",
  hl_mine_shop: "Cửa hàng của tôi",
  hl_shop_name: "Tên cửa hàng",
  hl_input_shop_name: "Xin vui lòng nhập tên cửa hàng",
  hl_add_shop_name: "Thêm tên cửa hàng",
  hl_update_shop_name: "Sửa tên cửa hàng",
  hl_update_suc: "Sửa đổi thành công",

  hl_rabate_01: "Hoa hồng cấp thấp hơn",
  hl_rabate_02: "Hồ sơ giảm giá",
  hl_rabate_03: "Tỷ lệ chiết khấu",
  hl_rabate_04: "Tổng số tiền hoa hồng",
  hl_rabate_05: "Tổng doanh số",
  hl_rabate_06: "Số tiền bán hàng",
  hl_rabate_07: "Số tiền hoàn lại",
  hl_rabate_08: "Thời gian đăng ký",
  hl_rabate_09: "Hỗ trợ cấp 1",
  hl_rabate_10: "Hỗ trợ cấp 2",
  hl_rabate_11: "Hỗ trợ cấp 3",

  dd_order_01: "Hủy đơn hàng",
  dd_order_02: "Trả lại",
  dd_order_03: "Trở về",
  dd_order_04: "Đã trả lại",

  _t_p_qr: "Xác nhận",
  _t_p_txt01:
    "Có nên hủy đơn hàng không? Việc hủy đơn hàng là không thể hủy ngang và không thể tìm thấy! ! ! ",
  _t_p_txt02: "Xin vui lòng xác nhận xem đơn hàng này có cần trả lại không! ",
  _t_p_txt03: "Hủy thành công",
  _t_p_txt04: "Yêu cầu trả lại đã được gửi",

  showcase_01: "Đặt hàng",
  showcase_02: "Đặt hàng thành công",
  showcase_03: "Xin vui lòng nhập ID bạn bè của bạn",

  goods_price: "Giá",

  u_pwd_tips:
    "Vui lòng liên hệ bộ phận chăm sóc khách hàng để thay đổi mật khẩu bảo mật",

  g_to_be_purchased: "sẽ được mua",
  g_purchasing_and_shipping: "Mua hàng và vận chuyển",
  g_suer_purchase_order: "Xác nhận mua hàng",

  hl_star_rating_text_1: "Người bán một sao",
  hl_star_rating_text_2: "Thương gia hai sao",
  hl_star_rating_text_3: "Thương gia ba sao",
  hl_star_rating_text_4: "Người bán bốn sao",
  hl_star_rating_text_5: "Người bán năm sao",

  hl_auth_tips: "Xác thực tên thật đã được gửi và đang được xem xét...",

  ml_01: "Chưa đọc",
  ml_02: "Đọc",
  ml_03: "Nội dung tin nhắn...",
  ml_04: "Gửi",
  ml_05: "Đang tải lên...",

  gl_sale_price: "Giá bán",

  wd_fee: "phí xử lý",
  wd_receipt: "Đã đến thực tế",
  wd_receipt_way: "Chọn phương thức thanh toán hiện tại",
  wd_bank_info: "Thông tin ngân hàng",
  wd_bank_info_tip: "Định dạng: Tên-Tên ngân hàng-Tài khoản-Số thẻ ngân hàng",
  wd_success: "Rút tiền thành công",
  wd_success_tip:
    "Đơn rút tiền của bạn đã thành công và dự kiến ​​sẽ đến tài khoản của bạn trong vòng 24 giờ",

  g_removed: "Đã xóa",
  g_sale_ing: "Đang giảm giá",

  sd_01: "Sản phẩm được đề xuất",
  sd_02: "Thêm",
  sd_03: "Cửa hàng được đề xuất",
  sd_04: "Sản phẩm hiện tại",
  sd_05: "Một",
  sd_06: "Xem cửa hàng",
  sd_07: "Cửa hàng sản phẩm",
  sd_08: "Kho hoạt động",
  sd_09: "Cửa hàng bất hợp pháp",

  mc_01: "Đã ký",
  mc_02: "Đã trả tiền",
  mc_03: "Thời gian ký tên",
  mc_04: "Thời gian thanh toán",
  mc_05: "Mã đơn hàng",
  mc_06: "Chữ ký",
  mc_07: "Người mua",
  mc_08: "Địa chỉ",
  mc_09: "Vùng",
  mc_10: "Liên hệ với người mua",
  mc_11: "Sẽ được đăng ký",
  mc_12: "Đã giải quyết",

  lgi_01: "Thông tin hậu cần",
  lgi_02: "So khớp kho hàng quốc tế",
  lgi_03: "Đang chuyển phát nhanh",
  lgi_04: "Đang chuyển tiếp",
  lgi_05: "Đang giao hàng",

  np_01: "Cảnh báo rủi ro",
  np_02: "Thông báo hệ thống",
  np_03: "Tôi đã đọc và hiểu",
  np_04: "Tin tức",

  sl_01: "Danh sách một cú nhấp chuột",

  w_pwd_info_01: "Mật khẩu rút tiền",
  w_pwd_info_02: "Lặp lại mật khẩu rút tiền",
  w_pwd_info_03: "Vui lòng nhập lại mật khẩu rút tiền",
  w_pwd_info_04: "Hai mật khẩu rút tiền không nhất quán",
  w_pwd_info_05: "Sai mật khẩu rút tiền",
  w_pwd_info_06:
    "Nếu bạn quên mật khẩu rút tiền, vui lòng liên hệ bộ phận chăm sóc khách hàng để thay đổi mật khẩu rút tiền",

  l_g_01: "Email",
  l_g_02: "Xin vui lòng nhập địa chỉ email của bạn",
  l_g_03: "Mã xác minh",
  l_g_04: "Vui lòng nhập mã xác minh",
  l_g_05: "Gửi",
  l_g_06: "Mật khẩu đăng nhập",
  l_g_07: "Xin vui lòng nhập mật khẩu đăng nhập của bạn",
  l_g_08: "Lặp lại mật khẩu đăng nhập",
  l_g_09: "Xin vui lòng nhập lại mật khẩu đăng nhập của bạn",
  l_g_10: "Đăng ký",
  l_g_11: "Đăng nhập",
  l_g_12: "Đã có tài khoản, đăng nhập",
  l_g_13: "Vào phần đăng ký",
  l_g_14: "Đăng xuất",
  l_g_15: "Xác nhận đăng xuất?",
  l_g_16: "Ngôn ngữ",
  l_g_17: "Hai mật khẩu đăng nhập không nhất quán",
  l_g_18:
    "Thông tin người dùng không tồn tại, vui lòng kiểm tra xem ID đã nhập chính xác chưa~",
  l_g_19: "Tên người dùng",
  l_g_20: "Vui lòng nhập tên người dùng",
  l_g_21: "Đăng nhập và sao chép",
  l_g_22: "Mật khẩu đăng nhập đã nhập sai, vui lòng nhập lại~",

  h_c_01: "Lợi nhuận đơn hàng",
  h_c_02: "Đã trả tiền",
  h_c_03: "Sẽ được vận chuyển",
  h_c_04: "Đã xác nhận",
  h_c_05: "Bán hàng",
  h_c_06: "Mã mời mở cửa hàng",
  h_c_07: "Vui lòng nhập mã mời cửa hàng",
  h_c_08: "Mời bạn bè",
  h_c_09: "Sai mã mời cửa hàng",
  h_c_10: "Mã mời cửa hàng đã được sử dụng",
  h_c_11: "Tên người dùng đã tồn tại",
  h_c_12:
    "Thông tin người dùng không tồn tại, vui lòng kiểm tra xem thông tin đã nhập chính xác chưa~",
  h_c_13: "Tài khoản đã bị đóng băng",

  g_c_01: "Bộ sưu tập của tôi",
  g_c_02: "Thu thập thành công",

  h_g_01: "Giá mua sản phẩm",
  h_g_02: "Số tiền lãi",
  h_g_03: "Giá bán sản phẩm",

  m_s_01: "Mã xác minh",
  m_s_02: "Vui lòng nhập mã xác minh",
  m_s_03: "Lỗi mã xác minh, vui lòng thử lại~",
  m_s_04: "Thương gia đã định cư",
  m_s_05: "Tên cửa hàng đã tồn tại~",

  _u_l_p: "Đổi mật khẩu đăng nhập",
  _u_l_p_01: "Mật khẩu cũ",
  _u_l_p_02: "Xin vui lòng nhập mật khẩu cũ",
  _u_l_p_03: "Mật khẩu mới",
  _u_l_p_04: "Xin vui lòng nhập mật khẩu mới",
  _u_l_p_05: "Lặp lại mật khẩu mới",
  _u_l_p_06: "Xin vui lòng nhập lại mật khẩu mới",
  _u_l_p_07: "*Sau khi thay đổi mật khẩu đăng nhập, bạn cần đăng nhập lại.",
  _u_l_p_08: "Mật khẩu cũ được nhập không chính xác~",

  bo_01: "Nhận thêm phiếu giảm giá",
  bo_02: "Địa chỉ email của bạn",
  bo_03: "Đăng ký",
  bo_04: "Dịch vụ khách hàng",
  bo_05: "Dịch vụ khách hàng trực tuyến",
  bo_06: "Trả lại và trao đổi",
  bo_07: "Chính sách quyền riêng tư",
  bo_08: "Chính sách hoàn trả",
  bo_09: "Giao và Nhận hàng",
  bo_10: "Chính sách người bán",
  bo_11: "Trung tâm người dùng",
  bo_12: "Đăng ký người dùng",
  bo_13: "Truy vấn đơn hàng",
  bo_14: "Ví của tôi",
  bo_15: "Giới thiệu về chúng tôi",
  bo_16: "Phương thức thanh toán",
  bo_17:
    "Người dùng của trạm toàn cầu Bán buôn MaCys đến từ 112 quốc gia trên thế giới và sử dụng USDT/ETH/BTC để thanh toán. USDT/ETH/BTC là phương thức giao dịch không biên giới có thể đạt được các giao dịch chi phí thấp ngay lập tức trên toàn thế giới mà không phải chờ đợi. , không có phí quốc tế",
  bo_18: "Liên kết dịch vụ khách hàng chưa được định cấu hình~",
  bo_19: "Vui lòng nhập đúng địa chỉ email",
  bo_20: "Đăng ký thành công",

  _ho_01: "xác thực 100%",
  _ho_02: "7 ngày trở lại",
  _ho_03: "Giảm giá vận chuyển",
  _ho_04: "Thanh toán an toàn",
  _ho_23: "Mặt hàng bán chạy",
  _ho_24: "Trở thành thương gia",
  _ho_25: "Chia sẻ hoa hồng",
  _ho_26: "cao nhất",
  _ho_27: "Tham gia ngay",

  "密码长度为6-12位": "{pwd_tip} dài 6-12 ký tự",
  密码中必须包含字母: "{pwd_tip} phải chứa các chữ cái",
  密码中必须数字: "{pwd_tip} phải chứa số",
  "密码中必须包含破折号(-)和下划线(_)":
    "{pwd_tip} phải chứa dấu gạch ngang (-) và dấu gạch dưới (_)",
};
