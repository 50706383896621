import { createStore } from "vuex";

export default createStore({
  state: {
    is_Q_S_T_H_M: true, // // Q,S,H,T,J,P,A,B,K,C,D,E,F,G为true
    is_Order_Verify: true, // Q,S,H,T,J,P,K,C,E,F,A为true,其他为false
    is_New_A: true, // A,B,K,Q,C,D,S,P,E,F,G,H为true,其他为false
    is_MQ_Service: false, // 是否使用美洽链接客服
    is_Withdraw_input: false, // 为true提现银行信息1个输入框, false提现银行信息3个输入框
    is_D: false, // D,G为true,其他为false 是否显示助力日志列表
    is_Q: true, // Q,S,P,E,F,B,H,A,K为true,其他为false
    is_C: false, // C站为true,其他为false
    is_Collect: false, // C站位true，其他为false 是否开启收藏产品功能
    is_WEB: true, // 当前分站是否是web版本的
    is_TK_LOGO: true, // false=D,G is_WEB: true,H5是否使用TK的logo

    // 002 01 004图片集
    index_01: require("../assets/img/002/ad.png"),
    index_02: require("../assets/img/002/pending_payment_icon.png"),
    index_03: require("../assets/img/002/ready_for_shipment_icon.png"),
    index_04: require("../assets/img/002/shipped_txt_icon.png"),
    index_05: require("../assets/img/002/paid_txt_icon.png"),
    index_06: require("../assets/img/002/my_shop.png"),
    index_07: require("../assets/img/002/product_categories_icon.png"),
    index_08: require("../assets/img/002/friend_invite_icon.png"),
    index_09: require("../assets/img/002/feedback_icon.png"),
    index_10: require("../assets/img/002/contact_service_icon.png"),
    index_11: require("../assets/img/002/ranking.png"),
    index_12: require("../assets/img/002/noLogin.png"),
    help_01: require("../assets/img/002/help.png"),
    new_01: require("../assets/img/002/newcomer.png"),
    wallet_01: require("../assets/img/002/icon_recharge.png"),
    wallet_02: require("../assets/img/002/icon_withdraw.png"),
    wallet_03: require("../assets/img/002/tip.png"),

    // 001 002 003 004
    // 测试系统时有文字，不是测试时填空
    // goodsDetail.vue，goodsList.vue，help.vue，index.vue，showcase.vue，supplier.vue，wallet.vue
    // test_txt: "(业务员测试专用系统)",
    test_txt: "",

    // 003图片集
    // index_01: require("../assets/img/003/ad.png"),
    // index_02: require("../assets/img/003/pending_payment_icon.png"),
    // index_03: require("../assets/img/003/ready_for_shipment_icon.png"),
    // index_04: require("../assets/img/003/shipped_txt_icon.png"),
    // index_05: require("../assets/img/003/paid_txt_icon.png"),
    // index_06: require("../assets/img/003/my_shop.png"),
    // index_07: require("../assets/img/003/product_categories_icon.png"),
    // index_08: require("../assets/img/003/friend_invite_icon.png"),
    // index_09: require("../assets/img/003/feedback_icon.png"),
    // index_10: require("../assets/img/003/contact_service_icon.png"),
    // index_11: require("../assets/img/003/ranking.png"),
    // index_12: require("../assets/img/003/noLogin.png"),
    // help_01: require("../assets/img/003/help.png"),
    // new_01: require("../assets/img/003/newcomer.png"),
    // wallet_01: require("../assets/img/003/icon_recharge.png"),
    // wallet_02: require("../assets/img/003/icon_withdraw.png"),
    // wallet_03: require("../assets/img/003/tip.png"),

    // 各国货币单位
    // 中文简体-zh-人民币
    // 印尼语（印度尼西亚）: id-印度尼西亚盾
    // 泰语（泰国）: th-泰铢
    // 法语: fr-法郎
    // 德语: de-马克
    // 英文: en-美元
    // 繁体: hk-港元
    // 日本: ja-日元
    // 俄语: russian-卢布
    // 韩国: ko-韩元
    // 越南语: vi-越南盾
    // 土耳其：teq-里拉
    // 波兰：bl-兹罗提
    currencyUnit: [
      { lang: "zh", unit: "RMB" },
      { lang: "id", unit: "IDR" },
      { lang: "th", unit: "THB" },
      { lang: "fr", unit: "FRF" },
      { lang: "de", unit: "DEM" },
      { lang: "en", unit: "USD" },
      { lang: "hk", unit: "HKD" },
      { lang: "ja", unit: "JPY" },
      { lang: "russian", unit: "SUR" },
      { lang: "ko", unit: "KRW" },
      { lang: "vi", unit: "VND" },
      { lang: "bl", unit: "PLN" },
      { lang: "teq", unit: "TL" },
      { lang: "ydl", unit: "EUR" },
    ],
    countryName: [
      { lang: "zh", country: "中国" },
      { lang: "id", country: "Indonesia" },
      { lang: "th", country: "ประเทศไทย" },
      { lang: "fr", country: "France" },
      { lang: "de", country: "Deutschland" },
      { lang: "en", country: "America" },
      { lang: "hk", country: "中國台湾" }, // 后台那边的key，地区相关hk=台湾
      { lang: "zh-hk", country: "中國香港" }, // 后台那边的key，地区相关zh-hk=香港
      { lang: "ja", country: "日本" },
      { lang: "russian", country: "Россия" },
      { lang: "ko", country: "대한민국" },
      { lang: "vi", country: "Việt Nam" },
      { lang: "bl", country: "Polska" },
      { lang: "teq", country: "Türkiye" },
      { lang: "ydl", country: "Italia" },
    ],
    countryLang: [
      { lang: "zh", value: "中文简体" },
      { lang: "en", value: "English" },
      { lang: "hk", value: "中文繁體" },
      { lang: "ja", value: "日本語" },
      { lang: "ko", value: "한국인" },
      { lang: "th", value: "แบบไทย" },
      { lang: "vi", value: "Tiếng Việt" },
      { lang: "id", value: "Indonesia" },
      { lang: "de", value: "Deutsch" },
      { lang: "fr", value: "Français" },
      { lang: "russian", value: "Русский" },
      { lang: "bl", value: "Polski" },
      { lang: "teq", value: "Türkçe" },
      { lang: "ydl", value: "Italiano" },
    ],
  },
  mutations: {
    // 在这里定义您的mutations
  },
  actions: {
    // 在这里定义您的actions
  },
  getters: {
    // 在这里定义您的getters
  },
  modules: {},
});
