export default {
  shop_center_txt: "Centrum handlowe",
  score_txt: "punkt",
  minute_txt: "minuta",
  wallet_txt: "portfel",
  today_txt: "Dzisiaj",
  week_txt: "W tym tygodniu",
  month_txt: "w tym miesiącu",
  year_txt: "cały rok",
  main_indicators_txt: "Główne wskaźniki",
  sale_price: "cena sprzedaży",
  expected_profit: "oczekiwany zysk",
  order_count: "ilość zamówienia",
  goods_click_count: "Liczba kliknięć produktów",
  supplier_txt: "Dostawca",
  friends_help_txt: "Przyjaciele pomagają",
  feedback_txt: "Opinia",
  feedback: "Opinia",
  online_service_txt_1: "Obsługa klienta wpłaty",
  online_service_txt_2: "Doradztwo biznesowe",
  q_and_a_txt: "Pytanie i odpowiedź",
  open_now_txt: "Otwórz",
  certification_activation_code: "Kod aktywacyjny certyfikatu",
  plz_write_invite_code: "Proszę wypełnić kod zaproszenia",
  time_txt: "czas",
  times_txt: "czas",

  wallet_total_assets: "Suma aktywów",
  balance_txt: "Saldo",
  pending_settlement_txt: "Oczekujące rozliczenie",
  recharge_txt: "Naładuj",
  withdraw_txt: "Wycofaj",
  fund_details_txt: "Szczegóły funduszu",
  no_more_txt: "Nigdy więcej",
  no_data_txt: "Jeszcze brak danych",
  detail_txt: "Szczegóły",
  num_txt: "Numer",
  money_txt: "kwota",
  remark_txt: "Uwagi",
  no_remark_txt: "Bez komentarza",
  tip_txt: "Wskazówka",
  tip_content_txt:
    "Obecnie ze względu na problemy walutowe gwarantowane przez sprzedawców w różnych krajach",
  need_contact_service: "Musisz skontaktować się z obsługą klienta",
  contact_service: "Skontaktuj się z obsługą klienta",

  feedback_title: "Prześlij swoją opinię",
  feedback_placeholder:
    "Ze względów bezpieczeństwa proszę nie podawać danych osobowych",
  submit_txt: "Prześlij",
  sure_txt: "OK",
  submit_suc_txt: "Przesłanie powiodło się",

  loading: "Ładowanie...",

  newer_action: "Aktywność nowicjusza",
  newer_traffic_package: "Pakiet ruchu wsparcia dla nowicjuszy",
  newer_get_millions_of_products: "Zdobądź miliony produktów",
  newer_get: "Otrzymano",
  newer_receive: "odbierz",
  newer_action_ruler: "Zasady aktywności nowicjuszy",
  newer_receive_suc: "Odebrano pomyślnie",

  help_friends_help: "Pomoc znajomych",
  help_your_friends_stores: "Pomóż swoim znajomym pomóc",
  get_store_product_heating: "Uzyskaj ogrzewanie produktów w sklepie",
  help_friends_txt: "Pomóż znajomym",
  help_friends_activity_rules: "Pomóż regułom aktywności znajomych",
  assistance_details: "Szczegóły pomocy",
  assistance_details_other: "Szczegóły otrzymanej pomocy",
  team_list: "Lista drużyn",
  help_friend_name: "Nazwa przyjaciela pomocy",
  plz_input: "Proszę wejść",
  plz_input_friend_name: "Wpisz imię swojego znajomego, który Ci pomoże",
  help_suc: "Pomóż odnieść sukces",

  online_service: "Obsługa klienta online",
  online_24_hour_service: "24-godzinne wsparcie online",
  customer_service: "Obsługa klienta",
  copy_and_add_id: "Skopiuj i dodaj ID LINII",
  plz_input_question_detail: "Proszę podać szczegóły swojego pytania",
  harry_reply_your: "Odpowiemy Ci tak szybko, jak to możliwe",
  click_copy: "Kliknij, aby skopiować",
  copy_suc: "Kopiowanie powiodło się",
  copy_fail: "Kopiowanie nie powiodło się",

  overall: "ogólnie",
  cooperate: "współpracować",
  cancel: "anuluj",
  supplier_num: "Numer dostawcy",
  no_use_supplier: "Brak dostępnego dostawcy",
  here_add_supplier: "Dodaj tutaj nowego dostawcę",
  add_supplier: "Dodaj dostawcę",
  plz_input_supplier_num: "Proszę wprowadzić numer dostawcy",
  add_suc: "Dodano pomyślnie",
  remove_suc: "Usunięto pomyślnie",

  new_products_launched: "Wprowadzono nowe produkty",
  profit_txt: "zysk",
  has_been_added: "Już na półkach",
  on_display: "Można postawić na półkach",
  coming_soon_txt: "Już wkrótce",
  sold_txt: "Sprzedany",
  removed_txt: "Usunięto",

  order_detail_txt: "Szczegóły zamówienia",
  waiting_express_delivery: "Oczekuję na ekspresową dostawę",
  customer_info: "Informacje o kliencie",
  order_good: "Zamów towar",
  order_num: "Numer zamówienia",
  order_date: "data zamówienia",
  order_content: "Podsumowanie zamówienia",
  purchase_price_txt: "Cena zakupu",
  income_txt: "Dochód",

  order_txt: "Zamów",
  pending_payment: "Oczekująca płatność",
  ready_for_shipment: "Gotowe do wysyłki",
  shipped_txt: "wysłano",
  paid_txt: "Dostarczono",
  good_num: "numer pozycji",
  pay_and_ship: "Płatność i wysyłka",
  pay_and_ship_all: "Zapłać wszystkim",
  pay_suc: "Płatność udana",
  no_order: "Nie ma jeszcze zamówienia",
  no_order_tips: "Tutaj możesz śledzić swoje zamówienia i zwroty",
  suer_order: "Potwierdź płatność",
  pay_way: "Metoda płatności",
  wallet_pay: "płatność portfelem",
  sure_pay: "Potwierdź płatność",
  paying_txt: "Płacę...",

  manage_showcase: "Zarządzaj Prezentacją",
  add_new_good: "Dodaj nowy produkt",
  heating_txt: "ogrzewanie",
  promotion: "promocja",
  promotion_tip: "Wskazówki dotyczące promocji",
  promotion_tip_txt:
    "Twoje aktualne punkty są mniejsze niż 200 i nie możesz zapłacić za swoje produkty",
  copy_link: "Skopiuj link",
  scan_qr_code: "Zeskanuj kod QR",

  about_good: "O produkcie",
  specification: "Specyfikacja",
  product_overview: "Przegląd produktu",

  top_tips_1: "Przenoszenie sklepu Douyin online",
  top_tips_2: "Obecnie obsługuje tylko zaproszenia wewnętrzne",
  active_place_holeder: "Proszę wejść",

  promotion_01: "Promocja",
  promotion_02: "Kwota promocji",
  promotion_03: "Proszę wpisać kwotę promocji",
  promotion_04: "Potwierdź promocję",
  promotion_05: "Promocja udana",

  bao_txt: "Współczynnik ekspozycji",
  bal_no: "Niewystarczające saldo",

  wallet_type_01: "Doładuj aplikację",
  wallet_type_02: "Wniosek o wypłatę",
  wallet_type_03: "Płatność",
  wallet_type_04: "Odliczenie systemowe",
  wallet_type_05: "Dochód z zysku",
  wallet_type_06: "Potrącenie płatności",
  wallet_type_07: "Zużycie dystrybucji",
  wallet_type_08: "Przychody z promocji",
  wallet_type_09: "Nagroda za asystę",
  wallet_type_10: "Zarejestruj się za darmo",
  wallet_type_11: "Nagroda za obrót",
  wallet_type_12: "Wypłata odrzucona",

  all_orders: "Wszystkie zamówienia",
  mine_orders: "Moje zamówienia",
  mine_services: "Moje usługi",
  _tzz: "Skakanie...",

  info_01: "Nazwa",
  info_02: "E-mail",
  info_03: "Dane kontaktowe",
  info_04: "Płeć",
  info_05: "Poufne",
  info_06: "Mężczyzna",
  info_07: "Kobieta",
  info_08: "Data urodzenia (rrrr-MM-dd)",
  info_09: "Kraj",
  info_10: "miasto",
  info_11: "Numer karty",
  info_12: "Typ karty",
  info_13: "Wydawca karty",
  info_14: "Data ważności (rrrr-MM-dd)",
  info_15: "Kod CVV",
  info_16: "Hasło zabezpieczające",
  info_17: "Powtórz hasło bezpieczeństwa",
  info_18: "Proszę wprowadzić ponownie hasło bezpieczeństwa",
  info_19: "Te dwa hasła bezpieczeństwa są niespójne",
  info_20: "Dowód osobisty/paszport",
  info_21: "Wybierz typ obrazu do przesłania",
  info_22: "Dowód osobisty (przód i tył)",
  info_23: "Paszport",
  info_24: "Prawo jazdy",
  info_25: "Zezwolenie na pobyt",
  info_26: "Świadectwo zdrowia",
  info_27: "Proszę przesłać zdjęcie identyfikacyjne",
  info_28: "Wysłano, proszę cierpliwie czekać",
  info_29: "Numer konta",
  info_30: "Nazwa banku",

  wl_withdraw_money: "Kwota wypłaty",
  wl_withdraw_code_201:
    "Rekordy wypłat są sprawdzane, proszę nie przesyłać ich ponownie",
  wl_withdraw_code_202: "Informacje o karcie bankowej są nieprawidłowe",
  wl_withdraw_code_400: "Wyjątek dotyczący rekordu wypłaty",
  wl_withdraw_code_204: "Nieprawidłowe hasło bezpieczeństwa",
  wl_withdraw_code_205: "Nie można wycofać salda depozytu zabezpieczającego",
  wl_withdraw_code_207:
    "Nie można wycofać oceny bieżącego użytkownika, proszę zwiększyć liczbę gwiazdek",
  wl_withdraw_code_208:
    "Liczba gwiazdek bieżącego użytkownika osiągnęła dzisiaj maksymalną liczbę wypłat",

  wl_withdraw_order_01: "Wszystkie",
  wl_withdraw_order_02: "Niesprawdzono",
  wl_withdraw_order_03: "Przejrzałem",
  wl_withdraw_order_04: "Wypłata nie powiodła się",
  wl_withdraw_order_05: "Numer",
  wl_withdraw_order_06: "Kwota wypłaty",
  wl_withdraw_order_07: "Metoda wypłaty",
  wl_withdraw_order_08: "Informacje o wypłacie",
  wl_withdraw_order_09: "Do sprawdzenia",
  wl_withdraw_order_10: "Zatwierdzono",
  wl_withdraw_order_11: "Zakończono",
  wl_withdraw_order_12: "Anulowano",
  wl_withdraw_order_13: "Zamrożone",
  wl_withdraw_order_14: "Przyczyna niepowodzenia wypłaty",

  hl_mine_invite_code: "Mój kod zaproszenia",
  hl_help_friend_invite_code: "Kod zaproszenia znajomego do pomocy",
  hl_certification_activation_code: "Kod zaproszenia",
  hl_plz_write_invite_code: "Proszę wprowadzić kod zaproszenia",
  hl_help_code_202: "Nieprawidłowy kod zaproszenia do pomocy",
  hl_help_code_203: "Kod zaproszenia do pomocy nie może być powiązany ze sobą",
  hl_help_code_205:
    "Osiągnięto maksymalną liczbę pomocy i dalsze wsparcie nie jest dozwolone",
  hl_help_code_206: "Obecny znajomy już pomógł i nie może pomóc ponownie",
  gl_visits: "Odwiedziny",

  _in_sp: "Ranking produktów",
  _r_k01: "Ranking",
  _r_k02: "Produkt",
  _r_k03: "ciepło",

  _zw_ph: "Nie ma jeszcze informacji o rankingu produktów!",
  _zf_201: "Nieprawidłowy status zamówienia",
  _zf_202: "Niewystarczające saldo aktywów",
  _zf_203: "Płatność nie powiodła się",
  _zf_204:
    "Transakcje handlowe zostały zamrożone, prosimy o kontakt z obsługą klienta",

  _withdraw_order_txt: "Wycofaj zlecenie",

  shop_num: "numer sklepu",
  plz_input_shop_num: "Proszę podać numer sklepu",
  shop_num_error: "Numer sklepu jest nieprawidłowy",
  supplier_num_error: "Numer dostawcy jest nieprawidłowy",
  supplier_num_has_bound: "Numer dostawcy został powiązany",

  sc_day_sale: "Dzienna sprzedaż",
  sc_week_sale: "Wyprzedaże tygodniowe",
  hl_mine_shop: "Mój sklep",
  hl_shop_name: "Nazwa sklepu",
  hl_input_shop_name: "Proszę wpisać nazwę sklepu",
  hl_add_shop_name: "Dodaj nazwę sklepu",
  hl_update_shop_name: "Zmień nazwę sklepu",
  hl_update_suc: "Modyfikacja powiodła się",

  hl_rabate_01: "Prowizja niższego poziomu",
  hl_rabate_02: "Rekord rabatu",
  hl_rabate_03: "Stosunek rabatu",
  hl_rabate_04: "Całkowita kwota rabatu",
  hl_rabate_05: "Sprzedaż ogółem",
  hl_rabate_06: "Kwota sprzedaży",
  hl_rabate_07: "Kwota rabatu",
  hl_rabate_08: "Czas rejestracji",
  hl_rabate_09: "Pomoc poziomu 1",
  hl_rabate_10: "Pomoc drugiego poziomu",
  hl_rabate_11: "Pomoc poziomu 3",

  dd_order_01: "Anuluj zamówienie",
  dd_order_02: "Zwrot",
  dd_order_03: "Powrót",
  dd_order_04: "Zwrócił",

  _t_p_qr: "Potwierdź",
  _t_p_txt01:
    "Czy anulować zamówienie? Anulowanie zamówienia jest nieodwracalne i nie można go odnaleźć!!!",
  _t_p_txt02: "Proszę potwierdzić, czy to zamówienie wymaga zwrotu!",
  _t_p_txt03: "Anulowanie powiodło się",
  _t_p_txt04: "Żądanie zwrotu zostało przesłane",

  showcase_01: "Złóż zamówienie",
  showcase_02: "Zamówienie złożone pomyślnie",
  showcase_03: "Proszę wprowadzić ID znajomego",

  goods_price: "cena",

  u_pwd_tips:
    "Skontaktuj się z obsługą klienta w celu zmiany hasła zabezpieczającego",

  g_to_be_purchased: "Do kupienia",
  g_purchasing_and_shipping: "Zakupy i wysyłka",
  g_suer_purchase_order: "Potwierdź zakup",

  hl_star_rating_text_1: "Sprzedawca jednogwiazdkowy",
  hl_star_rating_text_2: "Sprzedawca dwugwiazdkowy",
  hl_star_rating_text_3: "Sprzedawca Samsung",
  hl_star_rating_text_4: "Sprzedawca czterogwiazdkowy",
  hl_star_rating_text_5: "Sprzedawca pięciogwiazdkowy",

  hl_auth_tips:
    "Uwierzytelnienie prawdziwego imienia i nazwiska zostało przesłane i jest w trakcie sprawdzania...",

  ml_01: "Nieprzeczytane",
  ml_02: "Czytaj",
  ml_03: "Treść wiadomości...",
  ml_04: "Wyślij",
  ml_05: "Przesyłanie...",

  gl_sale_price: "cena sprzedaży",

  wd_fee: "opłata manipulacyjna",
  wd_receipt: "Rzeczywiste przybycie",
  wd_receipt_way: "Wybierz istniejącą metodę płatności",
  wd_bank_info: "Informacje bankowe",
  wd_bank_info_tip:
    "Format: Nazwa-Nazwa banku-Nazwa konta-Numer karty bankowej",
  wd_success: "Wypłacono pomyślnie",
  wd_success_tip:
    "Twój wniosek o wypłatę został pomyślnie rozpatrzony i oczekuje się, że dotrze na Twoje konto w ciągu 24 godzin",

  g_removed: "Usunięto",
  g_sale_ing: "W promocji",

  sd_01: "Polecane produkty",
  sd_02: "więcej",
  sd_03: "Polecany sklep",
  sd_04: "Aktualny produkt",
  sd_05: "jeden",
  sd_06: "Wyświetl sklep",
  sd_07: "Przechowuj produkty",
  sd_08: "Sklep z aktywnościami",
  sd_09: "Nielegalny sklep",

  mc_01: "Podpisano",
  mc_02: "Płatny",
  mc_03: "Czas podpisu",
  mc_04: "Czas płatności",
  mc_05: "Numer zamówienia",
  mc_06: "Podpis",
  mc_07: "Kupujący",
  mc_08: "Adres",
  mc_09: "Region",
  mc_10: "Skontaktuj się z kupującym",
  mc_11: "Do podpisu",
  mc_12: "Rozstrzygnięty",

  lgi_01: "Informacje logistyczne",
  lgi_02: "Dopasuj międzynarodowy magazyn towarów",
  lgi_03: "Dostawa ekspresowa w toku",
  lgi_04: "W transporcie",
  lgi_05: "Dostarczanie",

  np_01: "Ostrzeżenie o ryzyku",
  np_02: "Ogłoszenie systemowe",
  np_03: "Przeczytałem i rozumiem",
  np_04: "Wiadomości",

  sl_01: "Lista jednym kliknięciem",

  w_pwd_info_01: "Hasło wypłaty",
  w_pwd_info_02: "Powtórz hasło do wypłaty",
  w_pwd_info_03: "Proszę wprowadzić ponownie hasło do wypłaty",
  w_pwd_info_04: "Te dwa hasła do wypłat są niespójne",
  w_pwd_info_05: "Złe hasło do wypłaty",
  w_pwd_info_06:
    "Jeśli zapomniałeś hasła do wypłaty, skontaktuj się z obsługą klienta w celu zmiany hasła do wypłaty",

  l_g_01: "E-mail",
  l_g_02: "Proszę podać swój adres e-mail",
  l_g_03: "Kod weryfikacyjny",
  l_g_04: "Proszę wprowadzić kod weryfikacyjny",
  l_g_05: "Wyślij",
  l_g_06: "Hasło logowania",
  l_g_07: "Proszę podać hasło do logowania",
  l_g_08: "Powtórz hasło logowania",
  l_g_09: "Proszę wprowadzić ponownie hasło do logowania",
  l_g_10: "Zarejestruj się",
  l_g_11: "Zaloguj się",
  l_g_12: "Masz już konto, zaloguj się",
  l_g_13: "Przejdź do rejestracji",
  l_g_14: "Wyloguj się",
  l_g_15: "Potwierdzić wylogowanie?",
  l_g_16: "Język",
  l_g_17: "Te dwa hasła logowania są niespójne",
  l_g_18:
    "Informacje o użytkowniku nie istnieją, proszę sprawdzić, czy identyfikator został wprowadzony poprawnie~",
  l_g_19: "Nazwa użytkownika",
  l_g_20: "Proszę wprowadzić nazwę użytkownika",
  l_g_21: "Zaloguj się i skopiuj",
  l_g_22:
    "Hasło do logowania zostało wprowadzone niepoprawnie, proszę wprowadzić je ponownie~",

  h_c_01: "Zysk za zamówienie",
  h_c_02: "Płatny",
  h_c_03: "Do wysłania",
  h_c_04: "Potwierdzony",
  h_c_05: "Sprzedaż",
  h_c_06: "Kod zaproszenia do otwarcia sklepu",
  h_c_07: "Proszę wprowadzić kod zaproszenia do sklepu",
  h_c_08: "Zaproś znajomych",
  h_c_09: "Nieprawidłowy kod zaproszenia do sklepu",
  h_c_10: "Użyto kodu zaproszenia do sklepu",
  h_c_11: "Nazwa użytkownika już istnieje",
  h_c_12:
    "Informacje o użytkowniku nie istnieją, proszę sprawdzić, czy informacje zostały wprowadzone poprawnie~",
  h_c_13: "Konto zostało zamrożone",

  g_c_01: "Moja kolekcja",
  g_c_02: "Odbiór powiódł się",

  h_g_01: "Cena zakupu produktu",
  h_g_02: "Kwota zysku",
  h_g_03: "Cena sprzedaży produktu",

  m_s_01: "Kod weryfikacyjny",
  m_s_02: "Proszę wprowadzić kod weryfikacyjny",
  m_s_03: "Błąd kodu weryfikacyjnego, spróbuj ponownie~",
  m_s_04: "Kupiec się osiedlił",
  m_s_05: "Nazwa sklepu już istnieje~",

  _u_l_p: "Zmień hasło logowania",
  _u_l_p_01: "Stare hasło",
  _u_l_p_02: "Proszę wprowadzić stare hasło",
  _u_l_p_03: "Nowe hasło",
  _u_l_p_04: "Proszę wprowadzić nowe hasło",
  _u_l_p_05: "Powtórz nowe hasło",
  _u_l_p_06: "Proszę wprowadzić ponownie nowe hasło",
  _u_l_p_07: "*Po zmianie hasła logowania musisz zalogować się ponownie.",
  _u_l_p_08: "Stare hasło zostało wprowadzone niepoprawnie~",

  bo_01: "Zdobądź więcej kuponów",
  bo_02: "Twój adres e-mail",
  bo_03: "Subskrybuj",
  bo_04: "Obsługa Klienta",
  bo_05: "Obsługa klienta online",
  bo_06: "Zwroty i wymiany",
  bo_07: "Polityka Prywatności",
  bo_08: "Polityka zwrotów",
  bo_09: "Dostawa i odbiór",
  bo_10: "Polityka sprzedawcy",
  bo_11: "Centrum użytkownika",
  bo_12: "Rejestracja użytkownika",
  bo_13: "Zapytanie o zamówienie",
  bo_14: "Mój portfel",
  bo_15: "O nas",
  bo_16: "Metoda płatności",
  bo_17:
    "Użytkownicy globalnej stacji MaCys-Wholesale pochodzą ze 112 krajów na całym świecie i do rozliczeń używają USDT/ETH/BTC. USDT/ETH/BTC to metoda transakcyjna bez granic, która pozwala na natychmiastowe i tanie transakcje na całym świecie bez czekania. , brak opłat międzynarodowych",
  bo_18: "Łącze obsługi klienta nie jest skonfigurowane~",
  bo_19: "Proszę podać poprawny adres e-mail",
  bo_20: "Subskrypcja powiodła się",

  _ho_01: "100% autentyczny",
  _ho_02: "7 dni na zwrot",
  _ho_03: "Zniżka na wysyłkę",
  _ho_04: "Bezpieczna płatność",
  _ho_23: "Przedmioty sprzedające się na gorąco",
  _ho_24: "Zostań kupcem",
  _ho_25: "Wspólna prowizja",
  _ho_26: "najwyższy",
  _ho_27: "Dołącz teraz",

  "密码长度为6-12位": "{pwd_tip} ma długość 6-12 znaków",
  密码中必须包含字母: "{pwd_tip} musi zawierać litery",
  密码中必须数字: "{pwd_tip} musi zawierać cyfry",
  "密码中必须包含破折号(-)和下划线(_)":
    "{pwd_tip} musi zawierać myślniki (-) i podkreślenia (_)",
};
