import axios from "axios";
import QS from "qs";
import router from "@/router"; // 引入Vue Router实例
const is_WEB = true;
// 环境的切换

axios.defaults.baseURL = "https://tkq-api.zale.mobi/api/"; //分站Q-002-单独的客服，注意修改index.html
// axios.defaults.baseURL = "https://tks-api.zale.mobi/api/"; //分站S-002-单独的客服，注意修改index.html
// axios.defaults.baseURL = "https://tkh-api.zale.mobi/api/"; //分站H-002-单独的客服，注意修改index.html
// axios.defaults.baseURL = "https://tka-api.zale.mobi/api/"; //分站A-002-单独的客服，注意修改index.html
// axios.defaults.baseURL = "https://tkk-api.zale.mobi/api/"; //分站K

// axios.defaults.baseURL = "https://tkp-api.zale.mobi/api/"; //分站P-002-单独的客服，注意修改index.html
// axios.defaults.baseURL = "https://tke-api.zale.mobi/api/"; //分站E-002-单独的客服，注意修改index.html

// axios.defaults.baseURL = "https://tkc-api.zale.mobi/api/"; //分站C-002-单独的客服，注意修改index.html
// axios.defaults.baseURL = "https://tkd-api.zale.mobi/api/"; //分站D-002-单独的客服，注意修改index.html
// axios.defaults.baseURL = "https://tkg-api.zale.mobi/api/"; //分站G-001-单独的客服，注意修改index.html
// axios.defaults.baseURL = "https://tkb-api.zale.mobi/api/"; //分站B

// axios.defaults.baseURL = "https://tkf-api.zale.mobi/api/"; //分站F

// axios.defaults.baseURL = "https://tkj-api.zale.mobi/api/"; //分站J-001-业务员专属测试分台,无客服

// axios.defaults.baseURL = "https://tkt-api.zale.mobi/api/"; //分站T-002-单独的客服，注意修改index.html

// axios.defaults.baseURL = "https://tkm-api.zale.mobi/api/"; //分站M-001-单独的客服，注意修改index.html

// axios.defaults.baseURL = 'https://tiktoka-api.dotc.club/api/' //分站A-001-单独的客服，注意修改index.html
// axios.defaults.baseURL = "https://tiktokb-api.dotc.club/api/"; //分站B-001-单独的客服，注意修改index.html
// axios.defaults.baseURL = "https://tki-api.zale.mobi/api/"; //分站I-001-单独的客服，注意修改index.html
// axios.defaults.baseURL = 'https://tiktokl-api.dotc.club/api/' //分站L-001-单独的客服，注意修改index.html
// axios.defaults.baseURL = "https://tiktoko-api.dotc.club/api/"; //分站O-001-单独的客服，注意修改index.html
// axios.defaults.baseURL = "https://tkr-api.zale.mobi/api/"; //分站R-001-单独的客服，注意修改index.html

// axios.defaults.baseURL = "https://tkn-api.zale.mobi/api/"; //分站N-001

// axios.defaults.baseURL = 'https://tka-api.yunshangyp.com/api/' //分站A-004

// 请求超时时   间
axios.defaults.timeout = 10000;
// axios.defaults.headers['language'] = 'cn'
// post请求头
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded;charset=UTF-8";

// 请求拦截器
axios.interceptors.request.use(
  (config) => {
    // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    // const token = store.state.token
    const token = sessionStorage.getItem("token");
    const lang = sessionStorage.getItem("lang")
      ? sessionStorage.getItem("lang")
      : "en";
    lang && (config.headers.lang = lang);
    token && (config.headers.token = token);
    return config;
  },
  (error) => {
    return Promise.error(error);
  }
);

// 响应拦截器
axios.interceptors.response.use(
  (response) => {
    if (response.status && response.status === 200) {
      if (response.data.code == 401) {
        sessionStorage.removeItem("token");
        if (is_WEB == true) {
          router.push("./login");
        } else {
          router.push("/");
        }
      }
      // if (response.data.code == 102) {
      //   showFailToast(response.data.msg)
      // }
      // if (response.data.code == 104) {
      //   showFailToast(response.data.msg)
      // } else {
      return Promise.resolve(response);
      // }
    } else {
      return Promise.reject(response);
    }
  },
  // 服务器状态码不是200的情况
  (error) => {
    if (error.response && error.response.status) {
      return Promise.reject(error.response);
    }
  }
);
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: { ...params },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, QS.stringify({ ...params }))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}

// 图片上传
export function postForm(url, params) {
  axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}
