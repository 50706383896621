export default {
  shop_center_txt: "Alışveriş Merkezi",
  score_txt: "puan",
  minute_txt: "dakika",
  wallet_txt: "cüzdan",
  today_txt: "Bugün",
  week_txt: "Bu hafta",
  month_txt: "bu ay",
  year_txt: "tüm yıl",
  main_indicators_txt: "Ana göstergeler",
  sale_price: "satış fiyatı",
  expected_profit: "beklenen kar",
  order_count: "sipariş miktarı",
  goods_click_count: "Ürün tıklamalarının sayısı",
  supplier_txt: "Tedarikçi",
  friends_help_txt: "Arkadaşlar yardım etsin",
  feedback_txt: "Geri Bildirim",
  feedback: "Geri Bildirim",
  online_service_txt_1: "Müşteri Hizmetlerine Para Yatırın",
  online_service_txt_2: "İşletme Danışmanlığı",
  q_and_a_txt: "Soru ve Cevap",
  open_now_txt: "Aç",
  certification_activation_code: "Sertifika Etkinleştirme Kodu",
  plz_write_invite_code: "Lütfen davet kodunu girin",
  time_txt: "zaman",
  times_txt: "zaman",

  wallet_total_assets: "Toplam varlıklar",
  balance_txt: "Bakiye",
  pending_settlement_txt: "Çözümleme bekleniyor",
  recharge_txt: "Yeniden şarj et",
  withdraw_txt: "Çek",
  fund_details_txt: "Fon Ayrıntıları",
  no_more_txt: "Artık yok",
  no_data_txt: "Henüz veri yok",
  detail_txt: "Ayrıntılar",
  num_txt: "Sayı",
  money_txt: "tutar",
  remark_txt: "Açıklamalar",
  no_remark_txt: "Açıklama yok",
  tip_txt: "İpucu",
  tip_content_txt:
    "Şu anda çeşitli ülkelerdeki satıcılar tarafından taahhüt edilen para birimi sorunları nedeniyle",
  need_contact_service: "Müşteri hizmetleriyle iletişime geçmeniz gerekiyor",
  contact_service: "Müşteri hizmetleriyle iletişime geçin",

  feedback_title: "Fikrinizi geri bildirimde bulunun",
  feedback_placeholder:
    "Güvenlik nedeniyle lütfen kişisel bilgilerinizi eklemeyin",
  submit_txt: "Gönder",
  sure_txt: "Tamam",
  submit_suc_txt: "Gönderim başarılı",

  loading: "Yükleniyor...",

  newer_action: "Yeni Gelen Etkinliği",
  newer_traffic_package: "Yeni gelen destek trafik paketi",
  newer_get_millions_of_products: "Milyonlarca ürün alın",
  newer_get: "Alındı",
  newer_receive: "al",
  newer_action_ruler: "Yeni Gelen Etkinlik Kuralları",
  newer_receive_suc: "Başarıyla alındı",

  help_friends_help: "Arkadaşların Yardımı",
  help_your_friends_stores: "Arkadaşlarınızın yardım etmesine yardım edin",
  get_store_product_heating: "Mağaza ürününün ısıtılmasını alın",
  help_friends_txt: "Arkadaşlara yardım et",
  help_friends_activity_rules: "Arkadaşların etkinlik kurallarına yardım et",
  assistance_details: "Yardım ayrıntıları",
  assistance_details_other: "Alınan yardımın ayrıntıları",
  team_list: "Takım listesi",
  help_friend_name: "Arkadaş adına yardım et",
  plz_input: "Lütfen girin",
  plz_input_friend_name:
    "Lütfen size yardımcı olacak arkadaşınızın adını girin",
  help_suc: "Başarıya yardımcı olun",

  online_service: "Çevrimiçi Müşteri Hizmetleri",
  online_24_hour_service: "24 saat çevrimiçi destek",
  customer_service: "Müşteri Hizmetleri",
  copy_and_add_id: "HAT kimliğini kopyalayıp ekleyin",
  plz_input_question_detail: "Lütfen sorunuzun ayrıntılarını girin",
  harry_reply_your: "Size mümkün olan en kısa sürede cevap vereceğiz",
  click_copy: "Kopyalamak için tıklayın",
  copy_suc: "Kopyalama başarılı",
  copy_fail: "Kopyalama başarısız oldu",

  overall: "genel",
  cooperate: "işbirliği yapmak",
  cancel: "iptal",
  supplier_num: "Tedarikçi numarası",
  no_use_supplier: "Tedarikçi yok",
  here_add_supplier: "Buraya yeni tedarikçi ekleyin",
  add_supplier: "Tedarikçi ekle",
  plz_input_supplier_num: "Lütfen tedarikçi numarasını girin",
  add_suc: "Başarıyla eklendi",
  remove_suc: "Başarıyla kaldırıldı",

  new_products_launched: "Yeni ürünler piyasaya sürüldü",
  profit_txt: "kâr",
  has_been_added: "Zaten raflarda",
  on_display: "Raflara yerleştirilebilir",
  coming_soon_txt: "Çok yakında",
  sold_txt: "Satıldı",
  removed_txt: "Kaldırıldı",

  order_detail_txt: "Sipariş ayrıntıları",
  waiting_express_delivery: "Ekspres teslimat bekleniyor",
  customer_info: "Müşteri bilgileri",
  order_good: "Mal Siparişi Ver",
  order_num: "Sipariş numarası",
  order_date: "sipariş tarihi",
  order_content: "Sipariş Özeti",
  purchase_price_txt: "Satın alma fiyatı",
  income_txt: "Gelir",

  order_txt: "Sipariş",
  pending_payment: "Ödeme bekleniyor",
  ready_for_shipment: "Gönderime hazır",
  shipped_txt: "gönderildi",
  paid_txt: "Teslim edildi",
  good_num: "öğe numarası",
  pay_and_ship: "Ödeme ve Gönderim",
  pay_and_ship_all: "Hepsini öde",
  pay_suc: "Ödeme başarılı",
  no_order: "Henüz sipariş yok",
  no_order_tips: "Siparişlerinizi ve iadelerinizi buradan takip edebilirsiniz",
  suer_order: "Ödemeyi onayla",
  pay_way: "Ödeme yöntemi",
  wallet_pay: "cüzdan ödemesi",
  sure_pay: "Ödemeyi onayla",
  paying_txt: "Ödeme yapılıyor...",

  manage_showcase: "Vitrin'i Yönet",
  add_new_good: "Yeni ürün ekle",
  heating_txt: "ısıtma",
  promotion: "promosyon",
  promotion_tip: "Tanıtım İpuçları",
  promotion_tip_txt:
    "Mevcut puanınız 200'ün altında ve ürünleriniz için ödeme yapamıyorsunuz",
  copy_link: "Bağlantıyı kopyala",
  scan_qr_code: "QR kodunu tara",

  about_good: "Ürün hakkında",
  specification: "Şartname",
  product_overview: "Ürüne Genel Bakış",

  top_tips_1: "Douyin mağazasını çevrimiçine taşıma",
  top_tips_2: "Şu anda yalnızca dahili davetiyeler destekleniyor",
  active_place_holeder: "Lütfen girin",

  promotion_01: "Tanıtım",
  promotion_02: "Promosyon tutarı",
  promotion_03: "Lütfen promosyon tutarını girin",
  promotion_04: "Promosyonu onayla",
  promotion_05: "Promosyon başarılı",

  bao_txt: "Pozlama oranı",
  bal_no: "Yetersiz bakiye",

  wallet_type_01: "Yeniden Yükleme Uygulaması",
  wallet_type_02: "Para çekme başvurusu",
  wallet_type_03: "Ödeme",
  wallet_type_04: "Sistem kesintisi",
  wallet_type_05: "Kar geliri",
  wallet_type_06: "Ödeme Kesintisi",
  wallet_type_07: "Dağıtım tüketimi",
  wallet_type_08: "Promosyon geliri",
  wallet_type_09: "Yardım Ödülü",
  wallet_type_10: "Ücretsiz kayıt olun",
  wallet_type_11: "Ciro Ödülü",
  wallet_type_12: "Para çekme işlemi reddedildi",

  all_orders: "Tüm siparişler",
  mine_orders: "Siparişlerim",
  mine_services: "Hizmetlerim",
  _tzz: "Atlıyor...",

  info_01: "Ad",
  info_02: "E-posta",
  info_03: "İletişim bilgileri",
  info_04: "Cinsiyet",
  info_05: "Gizli",
  info_06: "Erkek",
  info_07: "Kadın",
  info_08: "Doğum tarihi (yyyy-AA-gg)",
  info_09: "Ülke",
  info_10: "şehir",
  info_11: "Kart numarası",
  info_12: "Kart türü",
  info_13: "Kartı Veren Kuruluş",
  info_14: "Son kullanma tarihi (yyyy-AA-gg)",
  info_15: "CVV kodu",
  info_16: "Güvenlik şifresi",
  info_17: "Güvenlik şifresini tekrarla",
  info_18: "Lütfen güvenlik şifresini tekrar girin",
  info_19: "İki güvenlik şifresi tutarsız",
  info_20: "Kimlik kartı/pasaport",
  info_21: "Yüklenecek görselin türünü seçin",
  info_22: "Kimlik kartı (ön ve arka)",
  info_23: "Pasaport",
  info_24: "Sürücü Ehliyeti",
  info_25: "Oturma izni",
  info_26: "Sağlık Sertifikası",
  info_27: "Lütfen kimlik fotoğrafını yükleyin",
  info_28: "Gönderildi, lütfen sabırla bekleyin",
  info_29: "Hesap numarası",
  info_30: "Banka Adı",

  wl_withdraw_money: "Çekilen tutar",
  wl_withdraw_code_201:
    "Para çekme kayıtları inceleniyor, lütfen tekrar göndermeyin",
  wl_withdraw_code_202: "Banka kartı bilgileri hatalı",
  wl_withdraw_code_400: "Para çekme kaydı istisnası",
  wl_withdraw_code_204: "Yanlış güvenlik şifresi",
  wl_withdraw_code_205: "Marj bakiyesi çekilemiyor",
  wl_withdraw_code_207:
    "Mevcut kullanıcının yıldız puanı geri alınamaz, lütfen yıldız puanını artırın",
  wl_withdraw_code_208:
    "Mevcut kullanıcının yıldız derecelendirmesi bugün maksimum para çekme sayısına ulaştı",

  wl_withdraw_order_01: "Tümü",
  wl_withdraw_order_02: "İncelenmedi",
  wl_withdraw_order_03: "İncelendi",
  wl_withdraw_order_04: "Para çekme işlemi başarısız oldu",
  wl_withdraw_order_05: "Sayı",
  wl_withdraw_order_06: "Çekim tutarı",
  wl_withdraw_order_07: "Para çekme yöntemi",
  wl_withdraw_order_08: "Para çekme bilgileri",
  wl_withdraw_order_09: "İncelenecek",
  wl_withdraw_order_10: "Onaylandı",
  wl_withdraw_order_11: "Tamamlandı",
  wl_withdraw_order_12: "İptal edildi",
  wl_withdraw_order_13: "dondurulmuş",
  wl_withdraw_order_14: "Para çekme işleminin başarısız olmasının nedeni",

  hl_mine_invite_code: "Davet kodum",
  hl_help_friend_invite_code: "Arkadaş davet koduna yardım et",
  hl_certification_activation_code: "Davetiye Kodu",
  hl_plz_write_invite_code: "Lütfen davet kodunu girin",
  hl_help_code_202: "Yanlış yardım daveti kodu",
  hl_help_code_203: "Yardım daveti kodu kendisine bağlanamaz",
  hl_help_code_205:
    "Maksimum yardım sayısına ulaşıldı ve daha fazla yardıma izin verilmiyor",
  hl_help_code_206: "Mevcut arkadaş zaten yardım etti ve tekrar yardım edemez",
  gl_visits: "Ziyaretler",

  _in_sp: "Ürün sıralaması",
  _r_k01: "Sıralama",
  _r_k02: "Ürün",
  _r_k03: "ısı",

  _zw_ph: "Henüz ürün sıralama bilgisi yok!",
  _zf_201: "Sipariş durumu anormal",
  _zf_202: "Yetersiz varlık bakiyesi",
  _zf_203: "Ödeme başarısız oldu",
  _zf_204:
    "Satıcı işlemleri donduruldu, lütfen müşteri hizmetleriyle iletişime geçin",

  _withdraw_order_txt: "Emiri geri çek",

  shop_num: "mağaza numarası",
  plz_input_shop_num: "Lütfen mağaza numarasını girin",
  shop_num_error: "Mağaza numarası hatalı",
  supplier_num_error: "Tedarikçi numarası yanlış",
  supplier_num_has_bound: "Tedarikçi numarası bağlandı",

  sc_day_sale: "Günlük satışlar",
  sc_week_sale: "Haftalık satışlar",
  hl_mine_shop: "Mağazam",
  hl_shop_name: "Mağaza adı",
  hl_input_shop_name: "Lütfen mağaza adını girin",
  hl_add_shop_name: "Mağaza adı ekle",
  hl_update_shop_name: "Mağaza adını değiştir",
  hl_update_suc: "Değiştirme başarılı",

  hl_rabate_01: "Alt seviye komisyon",
  hl_rabate_02: "İndirim Kaydı",
  hl_rabate_03: "İndirim oranı",
  hl_rabate_04: "Toplam indirim tutarı",
  hl_rabate_05: "Toplam satışlar",
  hl_rabate_06: "Satış tutarı",
  hl_rabate_07: "İndirim tutarı",
  hl_rabate_08: "Kayıt zamanı",
  hl_rabate_09: "1. Seviye yardım",
  hl_rabate_10: "İkinci düzey yardım",
  hl_rabate_11: "Seviye 3 yardımı",

  dd_order_01: "Siparişi iptal et",
  dd_order_02: "Geri Dön",
  dd_order_03: "Geri dönüyor",
  dd_order_04: "Geri verildi",

  _t_p_qr: "Onayla",
  _t_p_txt01:
    "Emir iptal edilsin mi? Emrin iptali geri alınamaz ve bulunamaz!!!",
  _t_p_txt02:
    "Lütfen bu siparişin iade edilmesi gerekip gerekmediğini onaylayın!",
  _t_p_txt03: "İptal başarılı",
  _t_p_txt04: "İade talebi gönderildi",

  showcase_01: "Sipariş ver",
  showcase_02: "Sipariş başarıyla verildi",
  showcase_03: "Lütfen arkadaş kimliğinizi girin",

  goods_price: "fiyat",

  u_pwd_tips:
    "Güvenlik şifresini değiştirmek için lütfen müşteri hizmetleriyle iletişime geçin",

  g_to_be_purchased: "Satın alınacak",
  g_purchasing_and_shipping: "Satın alma ve gönderim",
  g_suer_purchase_order: "Satın almayı onayla",

  hl_star_rating_text_1: "Tek yıldızlı satıcı",
  hl_star_rating_text_2: "İki yıldızlı satıcı",
  hl_star_rating_text_3: "Samsung Satıcısı",
  hl_star_rating_text_4: "Dört yıldızlı satıcı",
  hl_star_rating_text_5: "Beş yıldızlı satıcı",

  hl_auth_tips: "Gerçek ad kimlik doğrulaması gönderildi ve inceleniyor...",

  ml_01: "Okunmadı",
  ml_02: "Oku",
  ml_03: "Mesaj içeriği...",
  ml_04: "Gönder",
  ml_05: "Yükleniyor...",

  gl_sale_price: "satış fiyatı",

  wd_fee: "işlem ücreti",
  wd_receipt: "Gerçek varış",
  wd_receipt_way: "Mevcut bir ödeme yöntemini seçin",
  wd_bank_info: "Banka bilgileri",
  wd_bank_info_tip: "Biçim: Adı-Banka Adı-Hesap Banka-Kart Numarası",
  wd_success: "Başarıyla çekildi",
  wd_success_tip:
    "Para çekme başvurunuz başarılı oldu ve 24 saat içinde hesabınıza ulaşması bekleniyor",

  g_removed: "Kaldırıldı",
  g_sale_ing: "İndirimde",

  sd_01: "Önerilen ürünler",
  sd_02: "daha fazla",
  sd_03: "Önerilen mağaza",
  sd_04: "Geçerli ürün",
  sd_05: "bir",
  sd_06: "Mağazayı görüntüle",
  sd_07: "Ürünleri Mağazala",
  sd_08: "Etkinlik Mağazası",
  sd_09: "Yasadışı mağaza",

  mc_01: "İmzalandı",
  mc_02: "Ücretli",
  mc_03: "İmza zamanı",
  mc_04: "Ödeme süresi",
  mc_05: "Sipariş numarası",
  mc_06: "İmza",
  mc_07: "Alıcı",
  mc_08: "Adres",
  mc_09: "Bölge",
  mc_10: "Alıcıyla iletişime geç",
  mc_11: "İmzalanacak",
  mc_12: "Yerleşti",

  lgi_01: "Lojistik bilgileri",
  lgi_02: "Uluslararası mal deposunu eşleştir",
  lgi_03: "Ekspres teslimat devam ediyor",
  lgi_04: "Transit halinde",
  lgi_05: "Teslim Ediliyor",

  np_01: "Risk Uyarısı",
  np_02: "Sistem Duyurusu",
  np_03: "Okudum ve anladım",
  np_04: "Haberler",

  sl_01: "Tek tıklamayla listeleme",

  w_pwd_info_01: "Parolayı çekme",
  w_pwd_info_02: "Para çekme şifresini tekrarla",
  w_pwd_info_03: "Lütfen para çekme şifresini tekrar girin",
  w_pwd_info_04: "İki para çekme şifresi tutarsız",
  w_pwd_info_05: "Yanlış para çekme şifresi",
  w_pwd_info_06:
    "Para çekme şifrenizi unutursanız lütfen para çekme şifresini değiştirmek için müşteri hizmetleriyle iletişime geçin",

  l_g_01: "E-posta",
  l_g_02: "Lütfen e-posta adresinizi girin",
  l_g_03: "Doğrulama kodu",
  l_g_04: "Lütfen doğrulama kodunu girin",
  l_g_05: "Gönder",
  l_g_06: "Giriş şifresi",
  l_g_07: "Lütfen giriş şifrenizi girin",
  l_g_08: "Giriş şifresini tekrarla",
  l_g_09: "Lütfen giriş şifrenizi tekrar girin",
  l_g_10: "Kayıt Ol",
  l_g_11: "Giriş Yap",
  l_g_12: "Zaten bir hesabınız var, giriş yapın",
  l_g_13: "Kayıta git",
  l_g_14: "Oturumu kapat",
  l_g_15: "Çıkış yapmayı onaylıyor musunuz?",
  l_g_16: "Dil",
  l_g_17: "İki oturum açma parolası tutarsız",
  l_g_18:
    "Kullanıcı bilgisi mevcut değil, lütfen kimliğin doğru girilip girilmediğini kontrol edin~",
  l_g_19: "Kullanıcı adı",
  l_g_20: "Lütfen kullanıcı adını girin",
  l_g_21: "Giriş yapın ve kopyalayın",
  l_g_22: "Giriş şifresi yanlış girildi, lütfen tekrar girin~",

  h_c_01: "Sipariş Karı",
  h_c_02: "Ücretli",
  h_c_03: "Gönderilecek",
  h_c_04: "Onaylandı",
  h_c_05: "Satış",
  h_c_06: "Mağaza açılışı için davetiye kodu",
  h_c_07: "Lütfen mağaza davetiye kodunu girin",
  h_c_08: "Arkadaşlarını davet et",
  h_c_09: "Yanlış mağaza davet kodu",
  h_c_10: "Mağaza davet kodu kullanıldı",
  h_c_11: "Kullanıcı adı zaten mevcut",
  h_c_12:
    "Kullanıcı bilgisi mevcut değil, lütfen bilgilerin doğru girilip girilmediğini kontrol edin~",
  h_c_13: "Hesap donduruldu",

  g_c_01: "Koleksiyonum",
  g_c_02: "Toplama başarılı",

  h_g_01: "Ürün satın alma fiyatı",
  h_g_02: "Kar tutarı",
  h_g_03: "Ürün satış fiyatı",

  m_s_01: "Doğrulama kodu",
  m_s_02: "Lütfen doğrulama kodunu girin",
  m_s_03: "Doğrulama kodu hatası, lütfen tekrar deneyin~",
  m_s_04: "Satıcı yerleşti",
  m_s_05: "Mağaza adı zaten mevcut~",

  _u_l_p: "Giriş şifresini değiştir",
  _u_l_p_01: "Eski şifre",
  _u_l_p_02: "Lütfen eski şifreyi girin",
  _u_l_p_03: "Yeni şifre",
  _u_l_p_04: "Lütfen yeni bir şifre girin",
  _u_l_p_05: "Yeni şifreyi tekrarla",
  _u_l_p_06: "Lütfen yeni şifreyi tekrar girin",
  _u_l_p_07:
    "*Giriş şifresini değiştirdikten sonra tekrar giriş yapmanız gerekir.",
  _u_l_p_08: "Eski şifre yanlış girildi~",

  bo_01: "Daha fazla kuponunuzu alın",
  bo_02: "E-posta adresiniz",
  bo_03: "Abone ol",
  bo_04: "Müşteri Hizmetleri",
  bo_05: "Çevrimiçi Müşteri Hizmetleri",
  bo_06: "İade ve Değişimler",
  bo_07: "Gizlilik Politikası",
  bo_08: "İade Politikası",
  bo_09: "Teslimat ve Teslim Alma",
  bo_10: "Satıcı Politikası",
  bo_11: "Kullanıcı Merkezi",
  bo_12: "Kullanıcı kaydı",
  bo_13: "Sipariş Sorgulama",
  bo_14: "Cüzdanım",
  bo_15: "Hakkımızda",
  bo_16: "Ödeme yöntemi",
  bo_17:
    "MaCys-Wholesale küresel istasyonunun kullanıcıları dünya çapında 112 ülkeden geliyor ve ödeme için USDT/ETH/BTC kullanıyor. USDT/ETH/BTC, dünya çapında beklemeden anında düşük maliyetli işlemler gerçekleştirebilen sınırsız bir işlem yöntemidir. , uluslararası ücret yok",
  bo_18: "Müşteri hizmetleri bağlantısı yapılandırılmadı~",
  bo_19: "Lütfen doğru e-posta adresini girin",
  bo_20: "Abonelik başarılı",

  _ho_01: "%100 orijinal",
  _ho_02: "7 gün dönüş",
  _ho_03: "Gönderim indirimi",
  _ho_04: "Güvenli ödeme",
  _ho_23: "Sıcak satan ürünler",
  _ho_24: "Tüccar ol",
  _ho_25: "Paylaşılan komisyon",
  _ho_26: "en yüksek",
  _ho_27: "Şimdi katıl",

  "密码长度为6-12位": "{pwd_tip} 6-12 karakter uzunluğundadır",
  密码中必须包含字母: "{pwd_tip} harfler içermelidir",
  密码中必须数字: "{pwd_tip} sayı içermelidir",
  "密码中必须包含破折号(-)和下划线(_)":
    "{pwd_tip} kısa çizgi (-) ve alt çizgi (_) içermelidir",
};
